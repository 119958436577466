import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox, createStyles, IconButton, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ShareIcon from '@material-ui/icons/Share';
import copy from 'copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';

export type Settings = {
  reviews: boolean,
  director: boolean,
  cast: boolean,
  plot: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minHeight: "450px"
    },
    formControl: {
      margin: theme.spacing(3),
    },
    popoverContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: theme.spacing(2)
    },
    popoverText: {
      paddingLeft: theme.spacing(1),
    },
  }),
);

export default function SettingsDialog(props: {
  open: boolean,
  settings: Settings,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onClose: () => void
}) {
  const classes = useStyles();
  const {reviews, director, cast, plot} = props.settings;
  const [showSnackbar, setShowPopover] = React.useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title"
            className={classes.dialog}>
      <DialogTitle id="form-dialog-title">Settings</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select which attributes to display.
        </DialogContentText>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={reviews} onChange={props.handleChange} name="reviews"/>}
              label="Ratings (IMDB, etc.)"
            />
            <FormControlLabel
              control={<Checkbox checked={director} onChange={props.handleChange} name="director"/>}
              label="Director"
            />
            <FormControlLabel
              control={<Checkbox checked={cast} onChange={props.handleChange} name="cast"/>}
              label="Cast"
            />
            <FormControlLabel
              control={<Checkbox checked={plot} onChange={props.handleChange} name="plot"/>}
              label="Plot Summary"
            />
          </FormGroup>
        </FormControl>
        <div>
          <DialogContentText>
            Share this list
          </DialogContentText>
          <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            copy(window.location.href)
            setShowPopover(true);
            setTimeout(() => {
              setShowPopover(false);
            }, 1500)
          }}>
            <ShareIcon/>
          </IconButton>
        </div>
      </DialogContent>
      <Snackbar
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        key={"snackbar"}
        open={showSnackbar}
        onClose={() => {
          setShowPopover(false);
        }}
        message="Link copied!"
      />
      <DialogActions>
        <Button onClick={props.onClose} color="default">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}