import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {AutocompleteChangeReason} from '@material-ui/lab/Autocomplete';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useDebounce from "./debounce";
import {Typography} from "@material-ui/core";
import {SearchResult, searchTitle} from "./omdb";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      flex: 1,
    },
    searchRowContainer: {
      flexDirection: "column"
    },
    textContainer: {
      flex: 1,
      flexDirection: "column",
      align: "flex-start"
    },
    poster: {
      marginRight: theme.spacing(2),
    },
    resultRowContainer: {
      display: "flex",
      flex: 1,
      alignItems: "center"
    },
    resultRowText: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: theme.spacing(2),
    }
  }),
);

export default function SearchAutocomplete(props: { onSelect: (imdbID: string) => void }) {
  const classes = useStyles();
  const [query, setQuery] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<SearchResult[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("Loading...");

  const debouncedSearchTerm = useDebounce(query, 300);

  React.useEffect(() => {
    setLoading(open && options.length === 0);
  }, [open, options.length]);

  React.useEffect(() => {
    if (debouncedSearchTerm === "") {
      setLoadingText("No search query entered.")
      setOptions([])
    } else
      setLoadingText("Loading...");
    setLoading(true);
    let searchResposnePromise = searchTitle(debouncedSearchTerm);
    if (searchResposnePromise === null) {
      return;
    }
    searchResposnePromise.then(response => {
      if (response === undefined) {
        return;
      }
      if (response.success) {
        setOptions(response.results);
        setLoading(false);
      } else {
        setLoadingText(response.error ? response.error : "Catastrophic failure!");
        setOptions([]);
      }
    });
  }, [debouncedSearchTerm]);

  return (
    <Autocomplete
      id="asynchronous-search "
      value={null}
      className={classes.inputRoot}
      open={open}
      clearOnEscape={true}
      blurOnSelect={true}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(
        event: React.ChangeEvent<{}>,
        value: SearchResult | null,
        reason: AutocompleteChangeReason,
      ) => {
        if (reason === "select-option") {
          props.onSelect(value!.imdbID);
        }
      }}
      inputValue={query}
      onInputChange={(event, input) => {
        setQuery(input);
      }}
      getOptionSelected={(option, value) => option.imdbID === value.imdbID}
      getOptionLabel={(option) => option.Title}
      options={options}
      loading={loading}
      loadingText={loadingText}
      renderOption={(option, state) => (
        <div className={classes.resultRowContainer}>
          <img src={option.Poster} width="50px" alt="poster"/>
          <div className={classes.resultRowText}>
            <Typography variant="body1">{option.Title}</Typography>
            <Typography variant="overline">{option.Year} {option.Type}</Typography>
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search title..."
          variant="filled"
          color="secondary"
          size="small"
          autoComplete="false"
          InputProps={{
            ...params.InputProps,
            color: "secondary",
            endAdornment: null,
          }}
        />
      )}
    />
  );
}