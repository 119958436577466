import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchAutocomplete from "./SearchAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    }
  }),
);

export default function SearchAppBar(props: { onSelect: (imdbID: string) => void }) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <SearchAutocomplete onSelect={props.onSelect}/>
        </Toolbar>
      </AppBar>
    </div>
  );
}