import fetch from "cross-fetch";
import DataLoader from 'dataloader';

export type SearchResponse = {
  success: boolean;
  results: SearchResult[]
  error?: string;
}

export type SearchResult = {
  imdbID: string;
  Title: string;
  Year: string;
  Poster: string;
  Type: string;
}

type Rating = {
  Source: string;
  Value: string;
}

export type Entity = {
  Title: string;
  Year: string;
  Rated: string;
  Released: string;
  Runtime: string;
  Genre: string;
  Director: string;
  Writer: string;
  Actors: string;
  Plot: string;
  Language: string;
  Country: string;
  Awards: string;
  Poster: string;
  Ratings: Rating[];
  Metascore: string;
  imdbRating: string;
  imdbVotes: string;
  imdbID: string;
  Type: string;
  DVD: string;
  BoxOffice: string;
  Production: string;
  Website: "N/A";
  Response: "True"
}

type EntityResponse = {
  success: boolean;
  entity?: Entity;
  error?: string;
}

const API_KEY = process.env.REACT_APP_OMDB_API_KEY;

export const searchByTitle = async (q: string) => {
  if (q !== "") {
    const response = await fetch('https://www.omdbapi.com/?apikey=' + API_KEY + '&s=' + q);
    const responseJson = await response.json();
    if (responseJson.Response === "True") {
      let searchResults: SearchResult[] = responseJson.Search;
      let filteredResults = searchResults.filter(s => s.Poster !== "N/A");
      let response: SearchResponse = {
        success: true,
        results: filteredResults,
      };
      return response;
    } else {
      let response: SearchResponse = {
        success: false,
        results: [],
        error: responseJson.Error
      };
      return response;
    }
  }
};

const TitleSearchLoader = new DataLoader(
  async (query: ReadonlyArray<string>) => {
    const result = await searchByTitle(query[0]);
    return [result];
  },
  {batch: false},
);

export const searchTitle = (query: string) => {
  if (!query) return null;
  return TitleSearchLoader.load(query);
};

export const loadEntity = async (imdbId: string) => {
  const response = await fetch('https://www.omdbapi.com/?apikey=' + API_KEY + '&i=' + imdbId);
  const responseJson = await response.json();
  if (responseJson.Response === "True") {
    const response: EntityResponse = {
      success: true,
      entity: responseJson
    };
    return response;
  } else {
    const response: EntityResponse = {
      success: false,
      error: responseJson.Error
    };
    return response;
  }
};

const EntityLoader = new DataLoader(
  async (imdbId: ReadonlyArray<string>) => {
    const result = await loadEntity(imdbId[0]);
    return [result];
  },
  {batch: false},
);

export const getEntity = (imdbId: string) => {
  if (!imdbId) return null;
  return EntityLoader.load(imdbId);
};