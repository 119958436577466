import React from 'react';
import './App.css';
import {createMuiTheme, createStyles, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {Container, CssBaseline} from "@material-ui/core";
import SearchAppBar from "./SearchAppBar";
import {grey} from "@material-ui/core/colors";
import EntityCard from "./Card";
import {Entity, getEntity} from "./omdb";
import Fab from '@material-ui/core/Fab';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsDialog, {Settings} from "./SettingsDialog";
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffffff'
    },
    secondary: {
      main: grey["500"]
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      backgroundColor: theme.palette.secondary.main,
      minHeight: '100vh'
    },
    container: {
      flex: 1,
      paddingLeft: "5px",
      paddingRight: "5px",
      backgroundColor: "inherit"
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

function App() {
  const classes = useStyles();
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [urlEntityIds, setUrlEntityIds] = React.useState<string[]>(new URLSearchParams(history.location.search).getAll("id"));
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [settings, setSettings] = React.useState({
    reviews: true,
    director: true,
    cast: true,
    plot: true,
  });
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const off = history.listen((location) => {
      const currentUrlParams = new URLSearchParams(location.search);
      const excludes = currentUrlParams.getAll("exclude");
      setSettings({
        reviews: !excludes.includes("reviews"),
        director: !excludes.includes("director"),
        cast: !excludes.includes("cast"),
        plot: !excludes.includes("plot"),
      });

      const urlIds = currentUrlParams.getAll("id");
      setUrlEntityIds(urlIds);
    });
    return () => {
      off();
    }
  }, []);

  React.useEffect(() => {
    const currentIds = entities.map(e => e.imdbID);
    if (urlEntityIds.length > currentIds.length) {
      const currentIdsSet = new Set(currentIds);
      const newId = urlEntityIds.find(id => !currentIdsSet.has(id));
      const entityPromise = getEntity(newId!);
      if (entityPromise === null) {
        clearEntity(newId!);
      } else {
        entityPromise.then(entityReponse => {
          if (entityReponse.success) {
            addEntity(entityReponse.entity!);
          } else {
            clearEntity(newId!);
          }
        }).catch(() => clearEntity(newId!))
      }
    } else if (urlEntityIds.length < currentIds.length) {
      const urlEntityIdsSet = new Set(urlEntityIds)
      const removedId = currentIds.find(id => !urlEntityIdsSet.has(id));
      setEntities(entities.filter(e => e.imdbID !== removedId));
    }

  }, [urlEntityIds]);

  const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentUrlParams = new URLSearchParams(history.location.search);
    const excludes = currentUrlParams.getAll("exclude");
    if (!event.target.checked) {
      currentUrlParams.append('exclude', event.target.name);
    } else {
      currentUrlParams.delete("exclude");
      excludes.filter(e => e !== event.target.name).forEach(e => currentUrlParams.append('exclude', e));
    }
    history.push(history.location.pathname + "?" + currentUrlParams.toString());
  };

  const addEntity = (entity: Entity) => {
    setEntities([...entities, entity]);
  };

  const addEntities = (newEntities: Entity[]) => {
    setEntities(entities.concat(newEntities));
  };

  const selectEntity = (imdbID: string) => {
    const currentUrlParams = new URLSearchParams(history.location.search);
    currentUrlParams.append('id', imdbID);
    history.push(history.location.pathname + "?" + currentUrlParams.toString())
  };

  const clearEntity = (imdbID: string) => {
    const currentUrlParams = new URLSearchParams(history.location.search);
    const ids = currentUrlParams.getAll("id");
    currentUrlParams.delete("id");
    ids.filter(id => id !== imdbID).forEach(id => currentUrlParams.append('id', id));
    history.push(history.location.pathname + "?" + currentUrlParams.toString());
  };

  if (!loaded) {
    const currentUrlParams = new URLSearchParams(history.location.search);
    const excludes = currentUrlParams.getAll("exclude");
    if (excludes.length > 0) {
      setSettings({
        reviews: !excludes.includes("reviews"),
        director: !excludes.includes("director"),
        cast: !excludes.includes("cast"),
        plot: !excludes.includes("plot"),
      });
    }
    const initialEntityIds = currentUrlParams.getAll("id");
    Promise.all(initialEntityIds.map(id => getEntity(id)))
      .then(entityResponses => {
        let newEntities: Entity[] = entityResponses.filter(e => e!.success).map(e => e!.entity!);
        addEntities(newEntities);
      });
    setLoaded(true);
  }

  const renderCards = (settings: Settings) => {
    return entities.map(e => {
      return (<EntityCard key={e.imdbID} entity={e} settings={settings} onClose={clearEntity}/>);
    });
  };

  const fab = {
    color: 'primary' as 'primary',
    className: classes.fab,
    icon: <SettingsIcon/>,
    label: 'Add',
  };

  return (
    <div className={classes.background}>
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <SearchAppBar onSelect={imdbID => {
          selectEntity(imdbID)
        }}/>
        <Container className={classes.container}>
          {renderCards(settings)}
          <div style={{"height": "80px"}}/>
        </Container>
        <SettingsDialog open={settingsOpen} onClose={() => setSettingsOpen(false)} handleChange={handleSettingsChange}
                        settings={settings}/>
        <Fab aria-label={fab.label} className={fab.className} color={fab.color} onClick={() => setSettingsOpen(true)}>
          {fab.icon}
        </Fab>
      </ThemeProvider>
    </div>
  );
}

export default App;
