import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {createStyles, Theme} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import imdb from './assets/imdb.png';
import rtfresh from "./assets/rtfresh.png";
import rtrotten from "./assets/rtrotten.png";
import metacritic from "./assets/metacritic.svg";
import {Entity} from "./omdb";
import {Settings} from "./SettingsDialog";
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundColor: theme.palette.secondary.main,
      height: '100vh'
    },
    container: {
      flex: 1,
      paddingLeft: "5px",
      paddingRight: "5px",
      backgroundColor: "inherit"
    },
    card: {
      display: "flex",
      marginTop: theme.spacing(1)
    },
    cardTop: {
      display: "flex",
      flexDirection: "row"
    },
    content: {
      '&:last-child': {
        paddingBottom: '0px',
      },
      display: "flex",
      flex: 1,
      flexDirection: "column"
    },
    titleAndClose: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      justifyContent: "space-between"
    },
    castAndCrew: {
      display: "flex",
      flexDirection: "row"
    },
    castAndCrewRole: {
      minWidth: "55px",
      maxWidth: "55px"
    },
    review: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "6px"
    },
    reviewLogoContainer: {
      display: "flex",
      justifyContent: "center",
      marginRight: "5px",
    },
    reviews: {
      display: "flex",
      maxWidth: "80px",
      flexDirection: "row",
      justifyContent: "flex-start"
    },
    media: {
      height: 140,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
  }));

export default function EntityCard(props: { entity: Entity, settings: Settings, onClose: (imdbID: string) => void }) {
  const classes = useStyles();

  const releaseDateDurationPart = (
    <Typography variant="overline" color="textSecondary">
      {props.entity.Released} · {props.entity.Runtime}
    </Typography>
  );

  const metacriticPart = () => {
    if (props.entity.Metascore !== "N/A") {
      return (<div className={classes.review}>
        <div className={classes.reviewLogoContainer}>
          <img src={metacritic} alt='mc' style={{'height': '15px'}}/>
        </div>
        <Typography variant="overline" color="textSecondary">
          {props.entity.Metascore}
        </Typography>
      </div>);
    } else {
      return (<div/>);
    }
  };

  const rtPart = () => {
    let rt = props.entity.Ratings.filter(r => r.Source === "Rotten Tomatoes").map(r => {
      if (parseInt(r.Value.replace("%", "")) < 60) {
        return {logo: rtrotten, score: r.Value};
      } else {
        return {logo: rtfresh, score: r.Value};
      }
    }).pop();
    if (rt !== undefined) {
      return (
        <div className={classes.review}>
          <div className={classes.reviewLogoContainer}>
            <img src={rt.logo} alt='rt' style={{'height': '15px'}}/>
          </div>
          <Typography variant="overline" color="textSecondary">
            {rt.score}
          </Typography>
        </div>
      );
    }
    return (<div/>);
  };

  const reviewsPart = (<div className={classes.reviews}>
    <div className={classes.review}>
      <div className={classes.reviewLogoContainer}>
        <img src={imdb} alt='imdb' style={{'height': '15px'}}/>
      </div>
      <Typography variant="overline" color="textSecondary">
        {props.entity.imdbRating}
      </Typography>
    </div>
    {rtPart()}
    {metacriticPart()}
  </div>);

  const directorPart = <div className={classes.castAndCrew}>
    <Typography variant="caption" color="textPrimary" className={classes.castAndCrewRole}>
      Director:
    </Typography>
    <Typography variant="caption" color="textSecondary">
      {props.entity.Director}
    </Typography>
  </div>;

  const castPart = <div className={classes.castAndCrew}>
    <Typography variant="caption" color="textPrimary" className={classes.castAndCrewRole}>
      Cast:
    </Typography>
    <Typography variant="caption" color="textSecondary">
      {props.entity.Actors}
    </Typography>
  </div>;

  return (
    <Paper elevation={5}>
      <Card className={classes.card}>
        <CardActionArea href={"https://www.imdb.com/title/" + props.entity.imdbID} target="_blank">
          <div className={classes.cardTop}>
            <img
              className={classes.media}
              src={props.entity.Poster}
              alt="poster"
              style={{'maxHeight': '210px'}}
            />
            <CardContent className={classes.content}>
              <div className={classes.titleAndClose}>
                <Typography variant="h5">
                  {props.entity.Title}
                </Typography>
                <CloseIcon fontSize="small" color="secondary" onClick={e => {
                  e.preventDefault();
                  props.onClose(props.entity.imdbID);
                }}/>
              </div>
              {releaseDateDurationPart}
              {props.settings.reviews && reviewsPart}
              {props.settings.director && directorPart}
              {props.settings.cast && castPart}
            </CardContent>
          </div>
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              {props.settings.plot && props.entity.Plot}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Paper>
  );

}